const Download = () => {
  if (typeof window !== "undefined") {
    const { navigate } = require("gatsby");

    // iPhone Version:
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      window.location =
        "https://itunes.apple.com/us/app/gaston/id1308164057?ls=1&mt=8";
    }
    // Android Version:
    if (navigator.userAgent.match(/android/i)) {
      window.location =
        "https://play.google.com/store/apps/details?id=tel.gaston.app";
    }
    navigate("/");
  }
  return null;
};

export default Download;
